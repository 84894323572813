import _package from "../../../package.json";
import _defaultOptions from "../../constants/defaultOptions";
var exports = {};
const resolveURL = s => new URL(s, window.location.href).href;
const {
  version
} = _package;
const defaultOptions = _defaultOptions;

/*
 * Default options for browser worker
 */
exports = {
  ...defaultOptions,
  workerPath: typeof process !== "undefined" && process.env.TESS_ENV === "development" ? resolveURL(`/dist/worker.dev.js?nocache=${Math.random().toString(36).slice(3)}`) : `https://cdn.jsdelivr.net/npm/tesseract.js@v${version}/dist/worker.min.js`,
  /*
   * If browser doesn't support WebAssembly,
   * load ASM version instead
   */
  corePath: null
};
export default exports;