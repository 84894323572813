var exports = {};
/**
 * readFromBlobOrFile
 *
 * @name readFromBlobOrFile
 * @function
 * @access private
 */
const readFromBlobOrFile = blob => new Promise((resolve, reject) => {
  const fileReader = new FileReader();
  fileReader.onload = () => {
    resolve(fileReader.result);
  };
  fileReader.onerror = ({
    target: {
      error: {
        code
      }
    }
  }) => {
    reject(Error(`File could not be read! Code=${code}`));
  };
  fileReader.readAsArrayBuffer(blob);
});

/**
 * loadImage
 *
 * @name loadImage
 * @function load image from different source
 * @access private
 */
const loadImage = async image => {
  let data = image;
  if (typeof image === "undefined") {
    return "undefined";
  }
  if (typeof image === "string") {
    // Base64 Image
    if (/data:image\/([a-zA-Z]*);base64,([^"]*)/.test(image)) {
      data = atob(image.split(",")[1]).split("").map(c => c.charCodeAt(0));
    } else {
      const resp = await fetch(image);
      data = await resp.arrayBuffer();
    }
  } else if (typeof HTMLElement !== "undefined" && image instanceof HTMLElement) {
    if (image.tagName === "IMG") {
      data = await loadImage(image.src);
    }
    if (image.tagName === "VIDEO") {
      data = await loadImage(image.poster);
    }
    if (image.tagName === "CANVAS") {
      await new Promise(resolve => {
        image.toBlob(async blob => {
          data = await readFromBlobOrFile(blob);
          resolve();
        });
      });
    }
  } else if (typeof OffscreenCanvas !== "undefined" && image instanceof OffscreenCanvas) {
    const blob = await image.convertToBlob();
    data = await readFromBlobOrFile(blob);
  } else if (image instanceof File || image instanceof Blob) {
    data = await readFromBlobOrFile(image);
  }
  return new Uint8Array(data);
};
exports = loadImage;
export default exports;